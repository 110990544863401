<template>
    <div>
        <header class="page-header" v-bind:style="{ backgroundImage: 'url(images/banner-consultancy.jpg)' }">
            <div class="container">
                <h1>Pacific Smart Consultency</h1>
                <h4 style="color:white;">Large enough to handle, small enough to care</h4>
            </div>
            <!-- end container --> 
        </header>
        <!-- end page-header -->
        <section class="content-section" id="exim" data-background="#f9f7ef" style="background: rgb(249, 247, 239);">
        <div class="container">
            <div class="row align-items-center no-gutters">
            <div class="col-lg-6">
                <div class="side-list">
                <h2>Exim & Consultancy </h2>
                <div class="list-box">
                    <p>We are eminent in providing Export / Import Logistic Consultant services to our clients. Apart from carrying the cargo from one port to another, we put in continuous efforts for making the shipping process easy for our clients. Our customers just don't need to worry, as our professionals handle everything efficiently from handling of documentation, to processing, to door-to-door delivery.</p>
                </div>
                <!-- end list-box --> 
                </div>
                <!-- end side-list --> 
            </div>
            <!-- end col-6 -->
            <div class="col-lg-6">
                <figure class="side-image full-right"> <img src="images/side-image07.jpg" alt="Image"> </figure>
            </div>
            <!-- end col-6 --> 
            </div>
            <!-- end row --> 
        </div>
        <!-- end container --> 
        </section>
        <!-- end content-section -->
        <br />
        <br />
        <ContactUs @gotoPage="gotoPage" />
        <Group @gotoPage="gotoPage" />
    </div>
</template>

<script>
import ContactUs from '../../components/pages/contactus.vue';
import Group from '../../components/pages/group.vue';

export default {
    name: "Consultency",
    components: {
        ContactUs,
        Group
    },
    created: function() {
        window.scrollTo(0,0);
    },
    methods: {
        gotoPage(val) {
            window.scrollTo(0,0);
            this.$emit('page', val);
        },
    }
}
</script>