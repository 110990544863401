<template>
<section class="content-section">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="section-title">
          <h2>PACIFIC Group </h2>
          <p>We are committed to delivering professional services of the highest standard</p>
        </div>
        <!-- end section-title --> 
      </div>
      <!-- end col-12 -->
      <div class="col-lg-2 col-6">
        <div class="logo-item" v-on:click="gotoPage(10)"> 
          <a href="javascript:void(0);"><img src="images/movingarrow.jpg" alt="Image"> </a>
        </div>
        <!-- end logo-item --> 
      </div>
      <!-- col-3 -->
      <div class="col-lg-2 col-6">
        <div class="logo-item" v-on:click="gotoPage(11)"> 
          <a href="javascript:void(0);"><img src="images/airaviator.jpg" alt="Image"> </a>
        </div>
        <!-- end logo-item --> 
      </div>
      <!-- col-3 -->
      <div class="col-lg-2 col-6">
        <div class="logo-item" v-on:click="gotoPage(12)"> 
          <a href="javascript:void(0);"><img src="images/pwp.jpg" alt="Image"> </a>
        </div>
        <!-- end logo-item --> 
      </div>
      <!-- col-3 -->
      <div class="col-lg-2 col-6" v-on:click="gotoPage(13)">
        <div class="logo-item"> 
          <a href="javascript:void(0);"><img src="images/sealine.jpg" alt="Image"> </a>
        </div>
        <!-- end logo-item --> 
      </div>
      <div class="col-lg-2 col-6" v-on:click="gotoPage(14)">
        <div class="logo-item"> 
          <a href="javascript:void(0);"><img src="images/consultency.jpg" alt="Image"> </a>
        </div>
        <!-- end logo-item --> 
      </div>
      <div class="col-lg-2 col-6" v-on:click="gotoPage(15)">
        <div class="logo-item"> 
          <a href="javascript:void(0);"><img src="images/custombroker.jpg" alt="Image"> </a>
        </div>
        <!-- end logo-item --> 
      </div>
      <!-- col-3 --> 
    </div>
    <!-- end row --> 
  </div>
  <!-- end container --> 
</section>
</template>

<script>
export default {
    name: "Group",
    methods: {
      gotoPage: function(val) {
        this.$emit('gotoPage', val);
      }
    }
}
</script>