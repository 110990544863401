<template>
    <a href="javascript:void(0)"> 
        <img src="images/custom.jpg" srcset="images/custom.jpg" alt="Image" style="width:470px; margin-top:5px;"> 
    </a>   
</template>

<script>
export default {
    name: "Broker"
}
</script>