<template>
<div>
<header class="page-header" v-bind:style="{ backgroundImage: 'url(images/slide01.jpg)' }">
  <div class="container">
    <h1>Services</h1>
    <p>Providing high quality transportation services to all of our clients</p>
  </div>
  <!-- end container --> 
</header>
<!-- end page-header -->

<section class="content-section">
  <div class="container">
    <div class="row">
      <div class="col-12">
          <div class="section-title">
            <h2>Global Logistics & Transport solutions</h2>
            <p>Dedicated specialists taking care of your products</p>
          </div>
        </div>
    </div>
  </div>
</section>

<section class="content-section" id="logistic" data-background="#f9f7ef" style="background: rgb(249, 247, 239);">
  <div class="container">    
    <div class="row align-items-center no-gutters">
      <div class="col-lg-6">
        <div class="side-list">
          <h2>Shipping & Logistics Include: </h2>
          <div class="list-box">
            <ul>
              <li><i class="lni lni-checkmark"></i> Carrier Management </li>
              <li><i class="lni lni-checkmark"></i> Shipment Routing Optimization</li>
              <li><i class="lni lni-checkmark"></i> Cost Operations Improvement</li>
              <li><i class="lni lni-checkmark"></i> 24/7 Customer care</li>
              <li><i class="lni lni-checkmark"></i> Weekend Consolidation Program</li>
              <li><i class="lni lni-checkmark"></i> Inventory Control and Receiving</li>
              <li><i class="lni lni-checkmark"></i> Transportation Management</li>
              <li><i class="lni lni-checkmark"></i> Storage and Warehousing</li>              
            </ul>
          </div>
          <!-- end list-box --> 
        </div>
        <!-- end side-list --> 
      </div>
      <!-- end col-6 -->
      <div class="col-lg-6">
        <figure class="side-image full-right"> <img src="images/side-image02.jpg" alt="Image"> </figure>
      </div>
      <!-- end col-6 --> 
    </div>
    <!-- end row --> 
  </div>
  <!-- end container --> 
</section>
<!-- end content-section -->

<section class="content-section" id="cha" data-background="#ffffff">
    <div class="container">
        <div class="row align-items-center no-gutters">
            <div class="col-lg-6">
                <figure class="side-image full-left"> <img src="images/side-image03.jpg" alt="Image"> </figure>
            </div>
            <div class="col-lg-6">
                <div class="side-content right">
                <h2>CHA & Freight Forwarding</h2>
                <p>Pacific shipping International Freight System performs every aspect of getting your property where it needs to go. In addition to the shipping services of any typical international freight forwarding company, pacific shipping employs professional packers and craters to prepare your shipment for travel. For efficient and worry-free national and international shipping services, look no further than pacific shipping International Freight System. We look forward to working for you.</p>                
                </div>
            </div>
        </div>
    </div>
</section>

<section class="content-section" id="warehouse" data-background="#212223">
  <div class="container">
    <div class="row align-items-center no-gutters">
      <div class="col-lg-6">
        <div class="side-content dark">
          <h2>Storage and Warehousing</h2>
			<p>Warehouse planning and management are critical success factors today, driving peak performance, job efficiencies and bottom-line growth. Our proprietary technology helps automate warehouse operations and inventory management processes. PACIFIC SHIPPING can optimize organizational efficiencies, improve access to accurate and up-to-date information and provide a clear view of inventory levels. When you outsource to PACIFIC SHIPPING you are entrusting a team of experts backed by global reach and leading-edge logistics technologies to maximize sales, productivity and your customer’s satisfaction.</p>        
        </div>
        <!-- end side-content --> 
      </div>
      <!-- end col-6 -->
      <div class="col-lg-6">
        <figure class="side-image full-right"> <img src="images/side-image04.jpg" alt="Image"> </figure>
      </div>
      <!-- end col-6 --> 
    </div>
    <!-- end row --> 
  </div>
  <!-- end container --> 
</section>
<!-- end content-section -->

<section class="content-section" id="packing" data-background="#ffffff">
    <div class="container">
        <div class="row align-items-center no-gutters">
            <div class="col-lg-6">
                <figure class="side-image full-left"> <img src="images/side-image05.jpg" alt="Image"> </figure>
            </div>
            <div class="col-lg-6">
                <div class="side-content right">
                    <h2>Packing & Relocation</h2>
                    <p>Our entire team is kept abreast with the latest market trends and technology developments in the industry. Each professionals adopt a customer centric approach to exactly understand the requirements at the clients' end and offer a solution accordingly.
                        It is owing to their hard work and commitment that we have been able to constantly provide our clients with outstanding packing & moving services. Our team members are highly qualified and possess years of experience in the field of shipping & logistics. They take utmost care in carrying the consignment and take extra care while handling the breakable goods. Apart from packing & relocation, we also unpack and place the goods in the new location which in an added service.</p>          
                </div>
            </div>
        </div>
    </div>
</section>

<section class="content-section" id="tracking" v-bind:style="{ backgroundColor: '#212223'}">
    <div class="container">
        <div class="row align-items-center no-gutters">
            <div class="col-lg-6">
                <div class="side-content light">
                    <h2>Tracking and Transport</h2>
                    <p>Electronic Cargo Tracking Solution including container security tracking is only effective when the transport history are tracked and monitored from end-to-end with holistic approach. As part of the global integrated supply chain security system and solution in providing monitoring services, pacific shipping container transport tracking solution provides end-to-end real-time intermodal container and rail car cargo transport access and intrusion tracking using patented container security tracking technologies.</p>
                    <div class="accordion" id="accordion" role="tablist" style="background: #212223; color: #ffffff;">
                        <div class="card">
                        <div class="card-header" role="tab" id="headingOne" @click="showAccordion('collapseOne', 'headingOne')"> 
                            <a data-toggle="collapse" href="javascript:void(0);" aria-expanded="false" aria-controls="collapseOne" style="color: #ffffff;">Air Cargo</a> 
                        </div>
                        <div id="collapseOne" class="collapse" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordion">
                            <div class="card-body" style="color: #ffffff;">
                            Use an airway billing number for air freight tracking information.<br />
                                Find web-based air cargo tracking systems for immediate response to inquiries about where a product is located. <br />
                                Contract with outsourcing firms to implement air freight routing strategies. 
                            </div>
                            <!-- end card-body --> 
                        </div>
                        <!-- end collapse --> 
                        </div>
                        <!-- end card -->
                        <div class="card">
                        <div class="card-header" role="tab" id="headingTwo" @click="showAccordion('collapseTwo', 'headingTwo')"> 
                            <a class="collapsed" data-toggle="collapse" href="javascript:void(0)" aria-expanded="false" aria-controls="collapseTwo" style="color: #ffffff;"> Sea Cargo</a> 
                        </div>
                        <div id="collapseTwo" class="collapse" role="tabpanel" aria-labelledby="headingTwo" data-parent="#accordion">
                            <div class="card-body" style="color: #ffffff;"> 
                            Container number consists of four-letters owner code, six-digit serial number and a check digit. <br />
                            For example, MSKU1234565.     <br />              
                            Owner code shows the company that owns the container; it is not necessary the company that does transportation. Check digit can be used to identify mistypes in the container number.
                            </div>
                            <!-- end card-body --> 
                        </div>
                        <!-- end collapse --> 
                        </div>
                        <!-- end card --> 
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <figure class="side-image full-right"> <img src="images/side-image06.jpg" alt="Image"> </figure>
            </div>
            <!-- end col-6 -->  
        </div>
    </div>
</section>

<section class="content-section" id="exim" data-background="#f9f7ef" style="background: rgb(249, 247, 239);">
  <div class="container">
    <div class="row align-items-center no-gutters">
      <div class="col-lg-6">
        <div class="side-list">
          <h2>Exim & Consultancy </h2>
          <div class="list-box">
            <p>We are eminent in providing Export / Import Logistic Consultant services to our clients. Apart from carrying the cargo from one port to another, we put in continuous efforts for making the shipping process easy for our clients. Our customers just don't need to worry, as our professionals handle everything efficiently from handling of documentation, to processing, to door-to-door delivery.</p>
          </div>
          <!-- end list-box --> 
        </div>
        <!-- end side-list --> 
      </div>
      <!-- end col-6 -->
      <div class="col-lg-6">
        <figure class="side-image full-right"> <img src="images/side-image07.jpg" alt="Image"> </figure>
      </div>
      <!-- end col-6 --> 
    </div>
    <!-- end row --> 
  </div>
  <!-- end container --> 
</section>
<!-- end content-section -->

<br />
<br />

<ContactUs @gotoPage="gotoPage" />
<Group @gotoPage="gotoPage" />
</div>
</template>

<script>
import ContactUs from '../components/pages/contactus.vue'
import Group from '../components/pages/group.vue'
import $ from 'jquery'

export default {
    name: "Services",
    components: {
        ContactUs,
        Group
    },
    created: function() {
        window.scrollTo(0,0);
    },
    methods: {
    showAccordion(elementId, ele) {
      if($('#' + ele + ' a').attr('aria-expanded') === 'true') {
        $('#' + ele + ' a').attr('aria-expanded', false);
        $('#' + elementId).removeClass('show');
      }
      else {
        $('.collapse').removeClass('show');
        $('#' + elementId).addClass('show');
        $('a').attr('aria-expanded', false);
        $('#' + ele + ' a').attr('aria-expanded', true);
      }     
    },
    gotoPage(val) {
        this.$emit('page', val);
    }
  }
}
</script>