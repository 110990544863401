<template>
<footer class="footer">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-6">
        <figure class="logo"> <img src="images/logo.png" srcset="images/logo.png" alt="Image"> </figure>
        <p>+(91)-80-41169334 / 41678334<br>
          <a href="#">contact@pacific-shipping.com</a></p>
        <p>No.36, 11th Cross, 1st Stage, Indiranagar. Bangalore - 560038, Karnataka, India</p>
      </div>
      <!-- end col-4 -->
      <div class="col-lg-4 col-md-6">
        <h6 class="widget-title">Quick Links</h6>
        <ul class="footer-menu">
          <li v-on:click="gotoPage(3)"><a href="javascript:void(0)">Air Freight</a></li>
          <li v-on:click="gotoPage(3)"><a href="javascript:void(0)">Sea Freight</a></li>
          <li v-on:click="gotoPage(3)"><a href="javascript:void(0)">Ground Cargo</a></li>
          <li v-on:click="gotoPage(3)"><a href="javascript:void(0)">Packaging Options</a></li>
        </ul>
      </div>
      <!-- end col-4 -->
      <div class="col-lg-4">
        <h6 class="widget-title">Contact Us</h6>
        <form>
          <input type="email" id='txtemail' placeholder="enter your email address....">
          <input type="submit" value="Submit" @click="SubmitContactUsInfo">
        </form>
      </div>
      <!-- end col-4 -->
      <div class="col-12">
        <div class="footer-bottom"> <span>© 2021 Pacific Sea & Skylinks Pvt. Ltd.</span> </div>
        <!-- end footer-bottom --> 
      </div>
      <!-- end col-12 --> 
    </div>
    <!-- end row --> 
  </div>
  <!-- end container --> 
</footer>
<!-- end footer --> 
</template>

<script>
import $ from 'jquery';
import axios from 'axios';

export default {
    name: "Footer",
    methods: {
        gotoPage: function(val) {
            this.$emit('gotoPage', val);
        },
        SubmitContactUsInfo() {
          var isValid = true;
          if($('#txtemail').val() == '') {                
              $('#txtemail').css('border', '2px solid red');
              isValid = false;              
          }

          if(!isValid) {
              this.$swal("Error!", "Please fill all mandatory information!", "error");
              return false;
          }
          else {
              var emailpattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
              if(!emailpattern.test($('#txtemail').val())) {
                  this.$swal("Error!", "Email address is not valid", "error");
                  $('#txtemail').css('border', '2px solid red');
                  return false;
              }
          }

          var contactus = {
              "firstName": 'Guest',
              "lastName": '',
              "email": $('#txtemail').val(),
              "company": '',
              "phoneNumber": '',
              "country": '',
              "message": ''
          }

          const headers = {
                'Content-Type': 'application/json; charset=utf-8',
          }
          axios.post("https://pssplerp.azurewebsites.net/api/Email/sendContactUsEmail", contactus, {
                    headers: headers
               })
              .then(response => {
                  if(response.status == 200) {
                      this.$swal("Success!", "Information submitted successfully. Our team will contact you sortly.", "success");
                      $('#txtemail').val('');
                  }
              })
              .catch(error => {
                  this.$swal("Error!", error.message, "error");
                  console.error("There was an error!", error);
              });
        }
    }
}
</script>