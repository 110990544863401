<template>
    <a href="javascript:void(0)"> 
        <img src="images/logo_ma.jpg" srcset="images/logo_ma.jpg" alt="Image" style="width:190px; margin-top:5px;"> 
    </a>   
</template>

<script>
export default {
    name: "MALogo"
}
</script>