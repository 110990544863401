<template>
    <a href="javascript:void(0)"> 
        <img src="images/pwp_logo.jpg" srcset="images/pwp_logo.jpg" alt="Image" style="width:82px; margin-top:5px;"> 
    </a>   
</template>

<script>
export default {
    name: "PWPLogo"
}
</script>