<template>
    <div>
        <Home v-if="page === 1" @page="gotoPage" />
        <About v-else-if="page === 2" @page="gotoPage" />
        <Services v-else-if="page === 3" @page="gotoPage" />
        <Contact v-else-if="page === 4" @page="gotoPage" />
        <Quote v-else-if="page === 5" @page="gotoPage" />
        <MA v-else-if="page === 10" @page="gotoPage" />
        <PWP v-else-if="page === 12" @page="gotoPage" />
        <AA v-else-if="page === 11" @page="gotoPage" />
        <SP v-else-if="page === 13" @page="gotoPage" />
        <CON v-else-if="page === 14" @page="gotoPage" />
        <CB v-else-if="page === 15" @page="gotoPage" />
    </div>
</template>

<script>
import Home from './Home.vue'
import About from './About.vue'
import Services from './Services.vue'
import Contact from './Contact.vue'
import Quote from './quotation.vue'
import MA from './groups/movingarrow.vue'
import AA from './groups/airaviator.vue'
import SP from './groups/sealine.vue'
import CON from './groups/consultancy.vue'
import CB from './groups/broker.vue'
import PWP from './groups/pwp.vue'

export default {
    name: "Main",
    components: {
        Home,
        About,
        Services,
        Contact,
        Quote,
        MA,
        AA,
        SP,
        CON,
        CB,
        PWP
    },
    props: {
        pageIndex: Number
    },
    data() {
        return {
            page: this.pageIndex
        }
    },
    methods: {
        gotoPage(val) {
            this.$emit('displayPage', val);
        }
    },
    watch: {
        pageIndex(val) {
            this.page = val;
        }
    }
}
</script>