<template>
    <nav class="navbar">
        <div class="container">
            <div class="inner">                        
                <div class="custom-menu">
                    <ul>
                    <li><a href="#"></a></li>
                    <li><a href="#"></a></li>
                    </ul>
                </div>
                <!-- end custom-menu -->
                <div class="site-menu">
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li v-on:click="gotoPage(2)"><a href="javascript:void(0)">About</a></li>
                        <li v-on:click="gotoPage(3)"><a href="javascript:void(0)">Services</a></li>
                        <li v-on:click="gotoPage(4)"><a href="javascript:void(0)">Contact</a></li>
                    </ul>
                </div>
                <!-- end site-menu -->
                <div v-if="isActive === true" class="hamburger-menu open"
                    v-on:click="openSideBar"> <span></span> <span></span> <span></span> 
                </div>
                <div v-else class="hamburger-menu"
                    v-on:click="openSideBar"> <span></span> <span></span> <span></span> 
                </div>
                <!-- end hamburger-menu -->
                <div class="navbar-button" v-on:click="gotoPage(5)"> <a href="javascript:void(0)" target="_self">GET A QUOTE</a> 
                </div>
                <!-- end navbar-button --> 
            </div>
            <!-- end inner --> 
        </div>
        <!-- end container --> 
    </nav>
</template>

<script>
export default {
    name: "Header",
    data() {
        return {
            isActive: false
        }
    },
    methods: {
        openSideBar: function() {
            this.isActive = !this.isActive;
            this.$emit('displaySideBar', this.isActive);
        },
        gotoPage: function(val) {
            this.$emit('gotoPage', val);
        }
    }
}
</script>