<template>
    <div>
        <div class="topbar" style="height: 145px;">
            <div class="container">
                <Logo :pageIndex="pageIndex" />      
                <div class="social-media" style="padding-top: 90px;"> 
                    <span>Follow us</span>
                    <ul>
                        <li><a href="#"><img alt="facebook" src="images/facebook.png" height="16" /> <span>Facebook</span></a></li>
                        <li><a href="#"><img alt="twitter" src="images/twitter.png" height="16" />  <span>Twitter</span></a></li>
                        <li><a href="#"><img alt="linkedin" src="images/linkedin.png" height="16" /> <span>Linkedin</span></a></li>
                    </ul>
                </div>
                <!-- end social-media -->
            </div>            
        </div>
        <PageHeader @displaySideBar="displaySideBar" @gotoPage="gotoPage" />
    </div>
</template>

<script>
import PageHeader from './header.vue';
import Logo from './logo/logo.vue';

export default {
    name: 'Banner',
    components: {
        PageHeader,
        Logo
    },
    props:['pageIndex'],
    methods: {
        displaySideBar(val) {
            this.$emit('displaySideBar', val);
        },
        gotoPage: function(val) { 
            this.$emit('displayPage', val);
        }
    }
}
</script>