<template>
    <div>
        <header class="page-header" v-bind:style="{ backgroundImage: 'url(images/sea-banner.jpg)' }">
            <div class="container">
                <h1>SeaLine Perl</h1>
                <h4 style="color:white;">Large enough to handle, small enough to care</h4>
            </div>
            <!-- end container --> 
        </header>
        <!-- end page-header -->
        <section class="content-section">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="section-title">
                            <h2>Sea Cargo</h2>
                            <p>We provide a variety of FCL, LCL and special project transport solutions tailored to your needs.</p>
                        </div>
                    </div> 
                </div>
            </div>
        </section>
        <section class="content-section" id="cha" data-background="#ffffff">
            <div class="container">
                <div class="row align-items-center no-gutters">
                    <div class="col-lg-6">
                        <figure class="side-image full-left"> <img src="images/side-image03.jpg" alt="Image"> </figure>
                    </div>
                    <div class="col-lg-6">
                        <div class="side-content right">
                            <h2>FCL</h2>
                            <p>With our FCL service, we provide a competitive, secure and simple shipping method for your full container loads. Benefit from competitive prices and reserved cargo space on all main trade lanes.</p>                
                            <h2>LCL</h2>
                            <p>With our consolidation service, you only pay for the space you need. Professional handling at every hub is guaranteed thanks to our highly trained LCL teams.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <br />
        <br />
        <ContactUs @gotoPage="gotoPage" />
        <Group @gotoPage="gotoPage" />
    </div>
</template>

<script>
import ContactUs from '../../components/pages/contactus.vue';
import Group from '../../components/pages/group.vue';

export default {
    name: "SeaLine",
    components: {
        ContactUs,
        Group
    },
    created: function() {
        window.scrollTo(0,0);
    },
    methods: {
        gotoPage(val) {
            window.scrollTo(0,0);
            this.$emit('page', val);
        },
    }
}
</script>