<template>
    <div>
        <section class="content-section no-top-spacing">
        <div class="container">
            <div class="row">
            <div class="col-12">
                <div class="contact-bar">
                <h2>Contact us today!</h2>
                <p>Contact us today for your shipping <br>
                    and international logistics </p>
                <a href="javascript:void(0)" @click="gotoPage(4)">CLICK HERE TO CONTACT US!</a> </div>
                <!-- end contact-bar --> 
            </div>
            <!-- end col-12 --> 
            </div>
            <!-- end row --> 
        </div>
        <!-- end container --> 
        </section>
    </div>
</template>

<script>
export default {
    name: "ContactUs",
    methods: {
        gotoPage(val) {
          this.$emit('gotoPage', val);
      }
    }
}
</script>