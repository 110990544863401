<template>
    <div>
        <header class="page-header" v-bind:style="{ backgroundImage: 'url(images/custom-broker.jpg)' }">
            <div class="container">
                <h1>Custom Broker</h1>
                <h4 style="color:white;">Large enough to handle, small enough to care</h4>
            </div>
            <!-- end container --> 
        </header>
        <!-- end page-header -->
        <br />
        <br />
        <ContactUs @gotoPage="gotoPage" />
        <Group @gotoPage="gotoPage" />
    </div>
</template>

<script>
import ContactUs from '../../components/pages/contactus.vue';
import Group from '../../components/pages/group.vue';

export default {
    name: "CBroker",
    components: {
        ContactUs,
        Group
    },
    created: function() {
        window.scrollTo(0,0);
    },
    methods: {
        gotoPage(val) {
            window.scrollTo(0,0);
            this.$emit('page', val);
        },
    }
}
</script>