<template>
<div>
    <header class="page-header" v-bind:style="{ backgroundImage: 'url(images/quote.jpg)' }">
        <div class="container">      
        </div> 
    </header>
    <section class="content-section no-bottom-spacing bottom-bg-half" id="shipmentQuote">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="section-title text-left">
                    <h2>Get the approximate cost of <br>
                        delivery of your shipments</h2>
                    </div>
                </div>
                <div class="col-12">
                    <div class="calculator">
                        <div class="row inner" id="quoteform">
                            <div class="form-group col-12">
                                <div class="estimated-price" style="height: 80px;"><b>Shipment Details: </b></div>
                            </div>
                            <div class="form-group col-md-6"> <span>Distance</span>
                                <select style="height: 60px;" id="sltdistance">
                                    <option value="">Please select</option>
                                    <option value="10"> 100 km.</option>
                                    <option value="10"> 100 - 1000 km.</option>
                                    <option value="10"> > 1000 km.</option>
                                </select>
                            </div>
                            <div class="form-group col-md-6"> <span>Destination</span>
                                <input type="text" id="txtdestination">
                            </div>
                            <div class="form-group col-lg-3 col-md-6"> <span>Width</span>
                                <input type="text" id="txtwidth">
                            </div>
                            <div class="form-group col-lg-3 col-md-6"> <span>Height</span>
                                <input type="text" id="txtheight">
                            </div>
                            <div class="form-group col-lg-3 col-md-6"> <span>Length</span>
                                <input type="text" id="txtlength">
                            </div>
                            <div class="form-group col-lg-3 col-md-6"> <span>Weight</span>
                                <input type="text" id="txtweight">
                            </div>
                            
                            <!-- <div class="form-group col-lg-3 col-md-6"> <span>Fragile</span>
                                <div class="yes-no" id="yes-no">
                                    <input type="radio" name="rdo" id="fragileyes" value="15000" checked="">
                                    <input type="radio" name="rdo" id="fragileno" value="0">
                                    <div class="switch" style="height: 52px;">
                                        <label for="yes" style="top:-20%">Yes</label>
                                        <label for="no" style="top:-20%">No</label>
                                        <span></span> 
                                    </div>
                                </div>
                            </div> -->
                            <div class="form-group col-12">
                                <label>
                                    <input type="checkbox" id="chkexpress" checked="">
                                    Express Delivery</label>
                                <label>
                                    <input type="checkbox" id="chkinsurance">
                                    Insurance</label>
                                <label>
                                    <input type="checkbox" id="chkpackaging">
                                    Packaging</label>
                            </div>
                            <div class="form-group col-12">
                                <div class="estimated-price" style="height: 80px;"><b>Personal Details :</b></div>                
                            </div>
                            <div class="form-group col-lg-4">
                                <input type="text" id="txtfullname" placeholder="Full Name">
                            </div>
                            <div class="form-group col-lg-4 col-md-6">
                                <input type="text" id="txtemail" placeholder="Email Address">
                            </div>
                            <div class="form-group col-lg-4 col-md-6">
                                <input type="text" id="txtphone" placeholder="Phone No">
                            </div>
                            <div class="form-group col-12">
                                <button type="button" id="btnsubmit" class="custom-button" @click="SubmitQuoteInfo">Send Query</button>                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <br />
    <br />
    <ContactUs />
    <Group @gotoPage="gotoPage" />
</div>
</template>

<script>
import ContactUs from '../components/pages/contactus.vue'
import Group from '../components/pages/group.vue' 
import $ from 'jquery'
import axios from 'axios';

export default {
    name: "Quotation",
    components: {
        ContactUs,
        Group
    },
    created: function() {
        window.scrollTo(0,0);
    },    
    methods: {
        gotoPage(val) {
            this.$emit('page', val);
        },
        SubmitQuoteInfo() {
            var isValid = true;
            if($('#sltdistance').val() == '') {                
                $('#sltdistance').css('border', '2px solid red');
                isValid = false;
            }
            if($('#txtdestination').val() == '') {                
                $('#txtdestination').css('border', '2px solid red');
                isValid = false;
            }
            if($('#txtwidth').val() == '') {                
                $('#txtwidth').css('border', '2px solid red');
                isValid = false;
            }
            if($('#txtheight').val() == '') {                
                $('#txtheight').css('border', '2px solid red');
                isValid = false;
            }
            if($('#txtweight').val() == '') {                
                $('#txtweight').css('border', '2px solid red');
                isValid = false;
            }
            if($('#txtlength').val() == '') {                
                $('#txtlength').css('border', '2px solid red');
                isValid = false;
            }

            if($('#txtfullname').val() == '') {                
                $('#txtfullname').css('border', '2px solid red');
                isValid = false;
            }
            if($('#txtemail').val() == '') {                
                $('#txtemail').css('border', '2px solid red');
                isValid = false;
            }
            if($('#txtphone').val() == '') {                
                $('#txtphone').css('border', '2px solid red');
                isValid = false;
            }

            if(!isValid) {
                this.$swal("Error!", "Please fill all mandatory information!", "error");
                return;
            }
            else {
                var emailpattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
                if(!emailpattern.test($('#txtemail').val())) {
                    this.$swal("Error!", "Email address is not valid", "error");
                    $('#txtlastname').css('border', '2px solid red');
                    return;
                }

                var phonepattern = /^[0-9-+]+$/;
                if(!phonepattern.test($('#txtphone').val())) {
                    this.$swal("Error!", "Phone/mobile number is not valid", "error");
                    $('#txtphone').css('border', '2px solid red');
                    return;
                }
            }

            var quotation = {
                "name": $('#txtfullname').val(),
                "email": $('#txtemail').val(),
                "mobile": $('#txtphone').val(),
                "distance": $('#sltdistance').val(),
                "destination": $('#txtdestination').val(),
                "isExpressDelivery": $('#chkexpress').is(':checked'),
                "isInsured": $('#chkinsurance').is(':checked'),
                "isPackaged": $('#chkpackaging').is(':checked'),
                "quoteInfos": [
                    {
                        "width": $('#txtwidth').val(),
                        "height": $('#txtheight').val(),
                        "weight": $('#txtweight').val(),
                        "length": $('#txtlength').val()
                    }
                ]
            }

            const headers = {
                'Content-Type': 'application/json; charset=utf-8',
            }

            axios.post("https://pssplerp.azurewebsites.net/api/Email/sendQuotation", quotation, {
                    headers: headers
                })
                .then(response => {
                    if(response.status == 200) {
                        this.$swal("Success!", "Information submitted successfully. Our team will contact you sortly.", "success");
                        $('#contactform input[text]').val('');
                    }
                })
                .catch(error => {
                    this.$swal("Error!", error.message, "error");
                    console.error("There was an error!", error);
            });

        }
    }
}
</script>