<template>
<div>
<header class="page-header" v-bind:style="{ backgroundImage: 'url(images/slide04.jpg)' }">
  <div class="container">
    <h1>About Us</h1>
    <p><strong>Pacific Sea & Skylinks </strong> <br />
       today is one of the most reputed,  <br />
       trustworthy and leading shipping agency <br /> 
       in India, Singapore and Dubai.</p>
  </div>
  <!-- end container --> 
</header>
<!-- end page-header -->
<section class="content-section">
  <div class="section-title">
    <!-- <figure><img src="images/section-title-shape.png" alt="Image"></figure> -->
    <h2>Everyone within our business <br>
        understands our mission</h2>
    <p>It means we don’t just come to work to do a job, we come to work to make a difference.</p>
  </div>
  <!-- end section-title -->
  <div class="image-quote" v-bind:style="{ backgroundImage: 'url(images/quote-image01.jpg)' }">
    <div class="quote-box">
      <blockquote>The proud journey of Pacific Sea and Skylinks started in India in 1998, and privatization done in 2000. Managed by an experienced crew of qualified, competent and devoted shipping professionals and armed with the latest information technology. The company has dynamically grown manifold in size and strength over the duration of these few years.</blockquote>
      
    </div>
    <!-- end quote-box --> 
  </div>
  <!-- end image-quote --> 
</section>
<!-- end content-section -->

<section class="content-section no-spacing">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="section-title text-left">
          <h6>Our Values</h6>
          <h2>Giving customers <br>
            a unique experience <br>
            of partnership. </h2>
        </div>
        <!-- end section-title --> 
      </div>
      <!-- end col-6 -->
      <div class="col-lg-5 offset-lg-1">
        <div class="accordion" id="accordion" role="tablist">
          <div class="card">
            <div class="card-header" role="tab" id="headingOne" @click="showAccordion('collapseOne', 'headingOne')"> 
              <a data-toggle="collapse" href="javascript:void(0);" aria-expanded="true" aria-controls="collapseOne">Provide a service we are proud of</a> </div>
            <div id="collapseOne" class="collapse show" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordion">
              <div class="card-body"><p>We do this by providing a friendly, efficient 
                and personalised service combined with 
                innovative IT solutions that enable our 
                customers to always be in control of their 
                supply chains.</p></div>
              <!-- end card-body --> 
            </div>
            <!-- end collapse --> 
          </div>
          <!-- end card -->
          <div class="card">
            <div class="card-header" role="tab" id="headingTwo" @click="showAccordion('collapseTwo', 'headingTwo')"> 
              <a class="collapsed" data-toggle="collapse" href="javascript:void(0);" aria-expanded="false" aria-controls="collapseTwo"> Support each other and work together</a> </div>
            <div id="collapseTwo" class="collapse" role="tabpanel" aria-labelledby="headingTwo" data-parent="#accordion">
              <div class="card-body"> Our entire team is kept abreast with the latest market trends and technology developments in the industry. Each professionals adopt a customer centric approach to exactly understand the requirements at the clients' end and offer a solution accordingly.</div>
              <!-- end card-body --> 
            </div>
            <!-- end collapse --> 
          </div>
          <!-- end card -->
          <div class="card">
            <div class="card-header" role="tab" id="headingThree" @click="showAccordion('collapseThree', 'headingThree')"> 
              <a class="collapsed" data-toggle="collapse" href="javascript:void(0);" aria-expanded="false" aria-controls="collapseThree"> Make long-term business decisions</a> </div>
            <div id="collapseThree" class="collapse" role="tabpanel" aria-labelledby="headingThree" data-parent="#accordion">
              <div class="card-body">We are committed to the long-term, profitable 
                future of our business and aim to achieve sustained 
                growth that provides enhanced value to customers 
                and industry partners and opportunities</div>
              <!-- end card-body --> 
            </div>
            <!-- end collapse --> 
          </div>
          <!-- end card -->
          <div class="card">
            <div class="card-header" role="tab" id="headingFour" @click="showAccordion('collapseFour', 'headingFour')"> 
              <a class="collapsed" data-toggle="collapse" href="javascript:void(0);" aria-expanded="false" aria-controls="collapseFour"> To be pioneers in the track</a> </div>
            <div id="collapseFour" class="collapse" role="tabpanel" aria-labelledby="headingFour" data-parent="#accordion">
              <div class="card-body">We aim to study the needs of the customer requirements and give them the best services and then offer expert advice and Solutions to all logistics, export needs and import requirements. Being able to offer our customers a one stop shop and complete logistics solution is the reason for our success in the market.</div>
              <!-- end card-body --> 
            </div>
            <!-- end collapse --> 
          </div>
          <!-- end card -->
          <div class="card">
            <div class="card-header" role="tab" id="headingFive" @click="showAccordion('collapseFive', 'headingFive')"> 
              <a class="collapsed" data-toggle="collapse" href="javascript:void(0);" aria-expanded="false" aria-controls="collapseFive"> To make logistics affordable </a> </div>
            <div id="collapseFive" class="collapse" role="tabpanel" aria-labelledby="headingFive" data-parent="#accordion">
              <div class="card-body">Our mission is to constantly exceed customer expectations by providing superior freight forwarding and global transportation solutions.</div>
              <!-- end card-body --> 
            </div>
            <!-- end collapse --> 
          </div>
          <!-- end card --> 
          <div class="card">
            <div class="card-header" role="tab" id="headingSix" @click="showAccordion('collapseSix', 'headingSix')"> 
              <a class="collapsed" data-toggle="collapse" href="javascript:void(0);" aria-expanded="false" aria-controls="collapseSix"> To achieve perfection </a> </div>
            <div id="collapseSix" class="collapse" role="tabpanel" aria-labelledby="headingSix" data-parent="#accordion">
              <div class="card-body">We are customer focused, customer driven and strive passionately to provide an excellent and professional level of personalized service to our clients. To be a leader in the freight clearing and freight forwarding industry is what we strive for.</div>
              <!-- end card-body --> 
            </div>
            <!-- end collapse --> 
          </div>
          <!-- end card --> 
        </div>
        <!-- end accordion --> 
      </div>
      <!-- end col-5 --> 
    </div>
    <!-- end row --> 
  </div>
  <!-- end container --> 
</section>
<!-- end content-section -->
<br />
<br />
<ContactUs @gotoPage="gotoPage" />
<Group @gotoPage="gotoPage" />
</div>
</template>

<script>
import ContactUs from '../components/pages/contactus.vue'
import Group from '../components/pages/group.vue'
import $ from 'jquery'

export default {
  name: 'About',
  components: {
    ContactUs,
    Group
  },
  created: function() {
      window.scrollTo(0,0);
  },
  methods: {
    showAccordion(elementId, ele) {
      if($('#' + ele + ' a').attr('aria-expanded') === 'true') {
        $('#' + ele + ' a').attr('aria-expanded', false);
        $('#' + elementId).removeClass('show');
      }
      else {
        $('.collapse').removeClass('show');
        $('#' + elementId).addClass('show');
        $('a').attr('aria-expanded', false);
        $('#' + ele + ' a').attr('aria-expanded', true);
      }     
    },
    gotoPage(val) {
        this.$emit('page', val);
    }
  }
}
</script>
