<template>
    <a href="javascript:void(0)"> 
        <img src="images/pearl.jpg" srcset="images/pearl.jpg" alt="Image" style="width:160px; margin-top:5px;"> 
    </a> 
</template>

<script>
export default {
    name: "SeaLineLogo"
}
</script>