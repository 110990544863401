<template>
  <div id="app">
      <Home />
  </div>
</template>

<script>
import Home from './HomePage.vue'

export default {
  name: 'App',
  components: {
    Home
  }
}
</script>
