<template>
    <div class="container">
        <MALogo v-if="index === 10" />
        <AirLogo v-else-if="index === 11" />
        <PWPLogo v-else-if="index === 12" />
        <SeaLogo v-else-if="index === 13" />
        <ConsultLogo v-else-if="index === 14" />
        <Broker v-else-if="index === 15" />
        <PacificLogo v-else />                        
        <div class="social-media" style="padding-top: 90px;"> 
            <span>Follow us</span>
            <ul>
                <li><a href="#"><img alt="facebook" src="images/facebook.png" height="16" /> <span>Facebook</span></a></li>
                <li><a href="#"><img alt="twitter" src="images/twitter.png" height="16" />  <span>Twitter</span></a></li>
                <li><a href="#"><img alt="linkedin" src="images/linkedin.png" height="16" /> <span>Linkedin</span></a></li>
            </ul>
        </div>
        <!-- end social-media -->
    </div>
</template>
<script>
import PacificLogo from './pacificlogo.vue';
import MALogo from './malogo.vue';
import AirLogo from './airlogo.vue';
import SeaLogo from './sealogo.vue';
import ConsultLogo from './consultlogo.vue';
import Broker from './brokerlogo.vue';
import PWPLogo from './pwplogo.vue';

export default {
    name: "Logo",
    props:['pageIndex'],
    data () {
        return {
            index: 1
        }
    },
    components: {
        PacificLogo,
        MALogo,
        AirLogo,
        SeaLogo,
        ConsultLogo,
        Broker,
        PWPLogo
    },
    watch: {
        pageIndex(val) {
            this.index = val;
        }
    }
}
</script>