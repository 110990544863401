<template>
    <a href="/"> 
        <img src="images/logo.png" id="img_logo" srcset="images/logo.png" alt="Image"> 
    </a>
</template>

<script>
export default {
    name: "PacificLogo"
}
</script>