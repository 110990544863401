<template>
    <div>
        <header class="page-header" v-bind:style="{ backgroundImage: 'url(images/air-freight-banner.jpg)' }">
            <div class="container">
                <h1>Air Aviator</h1>
                <h4 style="color:white;">Large enough to handle, small enough to care</h4>
            </div>
            <!-- end container --> 
        </header>
        <!-- end page-header -->
        <section class="content-section">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="section-title">
                            <h2>Air Freight</h2>
                            <p>Our Digitized system can handle your complete Air Cargo Movement from billing to client reporting seamlessly.</p>
                        </div>
                    </div> 
                </div>
            </div>
        </section>
        <section class="content-section" id="cha" data-background="#ffffff">
            <div class="container">
                <div class="row align-items-center no-gutters">
                    <div class="col-lg-6">
                        <figure class="side-image full-left"> <img src="images/air-cargo.jpg" alt="Image"> </figure>
                    </div>
                    <div class="col-lg-6">
                        <div class="side-content right">
                            <h2>Air Freight Management</h2>
                            <p>Air freight forwarding is the process of organizing and planning the transport of freight from one point to another by air. 
                               Our experts process the entire air freight operations efficiency and saves time and reduces errors by allowing one-time data entry. 
                               We can operate all processes for handling air cargo, control of daily air freight operations, booking orders, quotations that help in saving time and increase efficiency.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <br />
        <br />
        <ContactUs @gotoPage="gotoPage" />
        <Group @gotoPage="gotoPage" />
    </div>
</template>

<script>
import ContactUs from '../../components/pages/contactus.vue';
import Group from '../../components/pages/group.vue';

export default {
    name: "AirAviator",
    components: {
        ContactUs,
        Group
    },
    created: function() {
        window.scrollTo(0,0);
    },
    methods: {
        gotoPage(val) {
            window.scrollTo(0,0);
            this.$emit('page', val);
        },
    }
}
</script>