<template>
    <a href="javascript:void(0)"> 
        <img src="images/air.jpg" srcset="images/air.jpg" alt="Image" style="width:170px;"> 
    </a>   
</template>

<script>
export default {
    name: "AIRLogo"
}
</script>