<template>
  <div>
    <header class="slider">
        <div class="main-slider">
            <div class="swiper-wrapper">
                <div class="swiper-slide">
                    <div class="slide-image" data-background="images/slide01.jpg"></div>
                    <!-- end slide-image -->
                    <div class="container">
                    <h1>Transport & <br>
                        Logistics</h1>
                    <p>Higher quality service by optimizing transport routes, <br />
                        means of transportation and reducing costs</p>
                    <a href="javascript:void(0)" @click="gotoPage(3)">LOGISTIC SERVICE</a> </div>
                    <!-- end container --> 
                </div>
                <!-- end swiper-slide -->
                <div class="swiper-slide">
                    <div class="slide-image" data-background="images/slide02.jpg"></div>
                    <!-- end slide-image -->
                    <div class="container">
                    <h1>Quickest & Safe <br>
                        Delivery</h1>
                    <p>Strong optimized supply chain infrastructure that will <br />
                        make a competitive advantage to your business</p>
                    <a href="javascript:void(0)" @click="gotoPage(3)">DISCOVER ALL</a> 
                    </div>
                    <!-- end container --> 
                </div>
                <!-- end swiper-slide -->
                <div class="swiper-slide">
                    <div class="slide-image" data-background="images/slide03.jpg"></div>
                    <!-- end slide-image -->
                    <div class="container">
                    <h1>Allways <br>
                        Trustable</h1>
                    <p>Effective quality measurement and monitoring will enable you <br />
                        to reduce costs and optimize your business.</p>
                    <a href="javascript:void(0)" @click="gotoPage(2)">MORE ABOUT US</a> </div>
                    <!-- end container --> 
                </div>
                <!-- end swiper-slide -->
            </div>
            <!-- end swiper-wrapper -->
            <div class="controls">
                <div class="button-prev"><i class="lni lni-chevron-left"></i>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="60" height="60" viewBox="0 0 30 30" xml:space="preserve">
                        <circle fill="none" stroke="#fff" stroke-width="1" cx="15" cy="15" r="15"></circle>
                    </svg>
                </div>
                <!-- end button-prev -->
                <div class="swiper-pagination"></div>
                <!-- end swiper-pagination -->
                <div class="button-next"><i class="lni lni-chevron-right"></i>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="60" height="60" viewBox="0 0 30 30" xml:space="preserve">
                            <circle fill="none" stroke="#fff" stroke-width="1" cx="15" cy="15" r="15"></circle>
                    </svg>
                </div>
                <!-- end button-next --> 
            </div>
            <!-- end controls -->
        </div>
        <!-- end main-slider --> 
    </header>
    <!-- end slider -->
    <section class="content-section">
        <div class="section-title">
            <!-- <figure><img src="images/section-title-shape.png" alt="Image"></figure> -->
            <h2>Welcome To Our Company <br>
            PACIFIC SEA & SKYLINKS Pvt. Ltd.</h2>    
        </div>
        <!-- end section-title -->
        <div class="image-quote" data-background="images/quote-image.jpg" style="background: url(&quot;images/quote-image.jpg&quot;);">
            <div class="quote-box">
            <blockquote>Pacific Sea & Skylinks today is one of the most reputed, trustworthy and leading shipping agency in India, Singapore and Dubai. </blockquote>
            <p>We have developed a state-of-the-art infrastructure that is equipped with requisite facilities to enable us to successfully serve different industries effectively.</p>
            </div>
            <!-- end quote-box --> 
        </div>
        <!-- end image-quote --> 
    </section>
    <!-- end section-note -->
    <section class="content-section no-spacing">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-7">
                    <div class="side-content">
                    <h2>Worldwide Logistics, 
                        Air Freight Forwarding, 
                        Road Haulage.</h2>
                    <p>PACIFIC SEA & SKYLINKS is a leading third party contract logistics company 
                        based in Bangalore, India. We specialise in providing 
                        supply-chain warehousing and transport services throughout the World.</p>
                    <a href="javascript:void(0)" @click="gotoPage(3)" class="custom-button">Discover All Solutions</a> </div>
                    <!-- end side-content --> 
                </div>
                <!-- end col-7 -->
                <div class="col-lg-5">
                    <figure class="side-image"> <img src="images/side-image01.jpg" alt="Image"> </figure>
                    <!-- end side-image --> 
                </div>
                <!-- end col-5 --> 
            </div>
            <!-- end row --> 
        </div>
        <!-- end container --> 
    </section>
    <!-- end section-note -->
    <section class="content-section">
        <div class="container">    
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6">
                    <div class="icon-box">
                        <div class="inner">
                            <figure><img src="images/icon01.png" alt="Image"></figure>
                            <h6>Leadership</h6>
                            <p>Experts who have extensive, 
                                hands-on experience in Shipping & Logistics</p>
                            <a href="javascript:void(0)" @click="gotoPage(4)" class="custom-link">Connect with an Expert</a> </div>
                        <!-- end inner --> 
                    </div>
                    <!-- end icon-box --> 
                </div>
                <!-- end col-4 -->
                <div class="col-lg-4 col-md-6">
                    <div class="icon-box">
                        <div class="inner">
                            <figure><img src="images/icon02.png" alt="Image"></figure>
                            <h6>Technology</h6>
                            <p>innovative and varied use of 
                            technology on the road, ocean, 
                            railways, in the air.</p>
                            <a href="javascript:void(0)" @click="gotoPage(4)" class="custom-link">Connect with an Expert</a> </div>
                        <!-- end inner --> 
                    </div>
                    <!-- end icon-box --> 
                </div>
                <!-- end col-4 -->
                <div class="col-lg-4 col-md-6">
                    <div class="icon-box">
                        <div class="inner">
                            <figure><img src="images/icon03.png" alt="Image"></figure>
                            <h6>Solution</h6>
                            <p>Global leaders in intermodal, 
                            less-than-truckload, supply 
                            chain management. </p>
                            <a href="javascript:void(0)" @click="gotoPage(4)" class="custom-link">Connect with an Expert</a> </div>
                        <!-- end inner --> 
                    </div>
                    <!-- end icon-box --> 
                </div>
                <!-- end col-4 --> 
            </div>
            <!-- end row --> 
        </div>
        <!-- end container --> 
    </section>
    <section class="content-section">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="section-title">
                    <!-- <figure><img src="images/section-title-shape.png" alt="Image"></figure> -->
                    <h2>Our Services</h2>
                    </div>
                    <!-- end section-title --> 
                </div>
                <!-- end col-12 -->
            </div>
            <!-- end row -->
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6">
                    <div class="image-content-box text-center">
                    <figure> <a href="javascript:void(0)" @click="gotoPage(3)"><img src="images/service-image01.jpg" alt="Image"></a></figure>
                            <h6>Shipping & Logistics</h6>
                    <!-- <p>Our dedicated fleet of vehicles 
                        operates nationally throughout the 
                        UK delivering both full, part, and 
                        consolidated loads.</p> -->
                    </div>
                    <!-- end image-content-box --> 
                </div>
                <!-- end col-4 -->
                <div class="col-lg-4 col-md-6">
                    <div class="image-content-box text-center">
                    <figure> <a href="javascript:void(0)" @click="gotoPage(3)"><img src="images/service-image02.jpg" alt="Image"></a> </figure>
                            <h6>Tracking & Transporting</h6>
                    <!-- <p>Sending smaller consignments of 
                        less than 10 pallets used to be 
                        expensive business, but we have a 
                        solution for you</p> -->
                    </div>
                    <!-- end image-content-box --> 
                </div>
                <!-- end col-4 -->
                <div class="col-lg-4 col-md-6">
                    <div class="image-content-box text-center">
                    <figure> <a href="javascript:void(0)" @click="gotoPage(3)"><img src="images/service-image03.jpg" alt="Image"></a> </figure>
                            <h6>CHA & Freight Forwarding</h6>
                    <!-- <p>In addition to our UK services, through 
                        our trusted and fully-vetted network of 
                        partners, we offer a full import and 
                        export service </p> -->
                    </div>
                    <!-- end image-content-box --> 
                </div>
                <!-- end col-4 --> 
            </div>
            <!-- end row -->
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6">
                    <div class="image-content-box text-center">
                    <figure> <a href="javascript:void(0)" @click="gotoPage(3)"><img src="images/service-image04.jpg" alt="Image" style="height: 362px;"></a> </figure>
                            <h6>Storage & Warehousing</h6>
                    <!-- <p>Our dedicated fleet of vehicles 
                        operates nationally throughout the 
                        UK delivering both full, part, and 
                        consolidated loads.</p> -->
                    </div>
                    <!-- end image-content-box --> 
                </div>
                <!-- end col-4 -->
                <div class="col-lg-4 col-md-6">
                    <div class="image-content-box text-center">
                    <figure> <a href="javascript:void(0)" @click="gotoPage(3)"><img src="images/service-image05.jpg" alt="Image" style="height: 362px;"></a> </figure>
                            <h6>Packing & Relocation</h6>
                    <!-- <p>Sending smaller consignments of 
                        less than 10 pallets used to be 
                        expensive business, but we have a 
                        solution for you</p> -->
                    </div>
                    <!-- end image-content-box --> 
                </div>
                <!-- end col-4 -->
                <div class="col-lg-4 col-md-6">
                    <div class="image-content-box text-center">
                    <figure> <a href="javascript:void(0)" @click="gotoPage(3)"><img src="images/service-image06.jpg" alt="Image" style="height: 362px;"></a> </figure>
                            <h6>Exim & Consultancy</h6>
                    <!-- <p>In addition to our UK services, through 
                        our trusted and fully-vetted network of 
                        partners, we offer a full import and 
                        export service </p> -->
                    </div>
                    <!-- end image-content-box --> 
                </div>
                <!-- end col-4 --> 
            </div>
            <!-- end row --> 
        </div>
        <!-- end container --> 
    </section>    
    <Group @gotoPage="gotoPage" />
  </div>
</template>

<script>
import Group from '../components/pages/group.vue';

export default {
  name: 'Home',
  components: {
    Group,
  },
  methods: {      
      gotoPage(val) {
          this.$emit('page', val);
      }
  }
}
</script>
