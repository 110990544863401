<template>
    <div>
        <header class="page-header" v-bind:style="{ backgroundImage: 'url(images/logistic_image.jpg)' }">
            <div class="container">
                <h1>Moving Arrow</h1>
                <h4 style="color:white;">Large enough to handle, small enough to care</h4>
            </div>
            <!-- end container --> 
        </header>
        <!-- end page-header -->
        <section class="content-section">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="section-title">
                            <h2>Global Logistics & Transport solutions</h2>
                            <p>As a market leader in national logistic forwarding, Moving Arrow excels in providing tailored transportation solutions for client's-specific needs.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="content-section" id="logistic" data-background="#f9f7ef" style="background: rgb(249, 247, 239);">
            <div class="container">    
                <div class="row align-items-center no-gutters">
                <div class="col-lg-6">
                    <div class="side-list">
                    <h2>Moving Arrow Include: </h2>
                    <div class="list-box">
                        <ul>
                            <li><i class="lni lni-checkmark"></i> Carrier Management </li>
                            <li><i class="lni lni-checkmark"></i> Shipment Routing Optimization</li>
                            <li><i class="lni lni-checkmark"></i> Cost Operations Improvement</li>
                            <li><i class="lni lni-checkmark"></i> 24/7 Customer care</li>
                            <li><i class="lni lni-checkmark"></i> Weekend Consolidation Program</li>
                            <li><i class="lni lni-checkmark"></i> Inventory Control and Receiving</li>
                            <li><i class="lni lni-checkmark"></i> Transportation Management</li>
                            <li><i class="lni lni-checkmark"></i> Storage and Warehousing</li>              
                        </ul>
                    </div>
                    <!-- end list-box --> 
                    </div>
                    <!-- end side-list --> 
                </div>
                <!-- end col-6 -->
                <div class="col-lg-6">
                    <figure class="side-image full-right"> <img src="images/side-image02.jpg" alt="Image"> </figure>
                </div>
                <!-- end col-6 --> 
                </div>
                <!-- end row --> 
            </div>
            <!-- end container --> 
        </section>        
        <section class="content-section" id="warehouse" data-background="#212223">
            <div class="container">
                <div class="row align-items-center no-gutters">
                <div class="col-lg-6">
                    <div class="side-content dark">
                    <h2>Storage and Warehousing</h2>
                        <p>Warehouse planning and management are critical success factors today, driving peak performance, job efficiencies and bottom-line growth. Our proprietary technology helps automate warehouse operations and inventory management processes. PACIFIC SHIPPING can optimize organizational efficiencies, improve access to accurate and up-to-date information and provide a clear view of inventory levels. When you outsource to PACIFIC SHIPPING you are entrusting a team of experts backed by global reach and leading-edge logistics technologies to maximize sales, productivity and your customer’s satisfaction.</p>        
                    </div>
                    <!-- end side-content --> 
                </div>
                <!-- end col-6 -->
                <div class="col-lg-6">
                    <figure class="side-image full-right"> <img src="images/warehouse.jpg" alt="Image"> </figure>
                </div>
                <!-- end col-6 --> 
                </div>
                <!-- end row --> 
            </div>
        <!-- end container --> 
        </section>
        <!-- end content-section -->
        <section class="content-section" id="packing" data-background="#ffffff">
            <div class="container">
                <div class="row align-items-center no-gutters">
                    <div class="col-lg-6">
                        <figure class="side-image full-left"> <img src="images/side-image05.jpg" alt="Image"> </figure>
                    </div>
                    <div class="col-lg-6">
                        <div class="side-content right">
                            <h2>Packing & Relocation</h2>
                            <p>Our entire team is kept abreast with the latest market trends and technology developments in the industry. Each professionals adopt a customer centric approach to exactly understand the requirements at the clients' end and offer a solution accordingly.
                                It is owing to their hard work and commitment that we have been able to constantly provide our clients with outstanding packing & moving services. Our team members are highly qualified and possess years of experience in the field of shipping & logistics. They take utmost care in carrying the consignment and take extra care while handling the breakable goods. Apart from packing & relocation, we also unpack and place the goods in the new location which in an added service.</p>          
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <br />
        <br />
        <ContactUs @gotoPage="gotoPage" />
        <Group @gotoPage="gotoPage" />
    </div>
</template>

<script>
import ContactUs from '../../components/pages/contactus.vue';
import Group from '../../components/pages/group.vue';
// import $ from 'jquery';

export default {
    name: "MovingArrow",
    components: {
        ContactUs,
        Group
    },
    created: function() {
        window.scrollTo(0,0);
        // $('#img_logo').attr('srcset', 'images/logo_ma.jpg');
        // $('#img_logo').css('width', '200px').css('margin-top', '5px');
    },
    methods: {
        gotoPage(val) {
            window.scrollTo(0,0);
            this.$emit('page', val);
        },
    }
}
</script>