<template>
    <div>
        <Banner @displaySideBar="displaySideBar" @displayPage="displayPage" :pageIndex="pageIndex" />
        <SideW v-if="showSideBar" :class="['side-widget active']" />
        <SideW v-else :class="['side-widget']" />
        <MainPage :pageIndex="pageIndex" @displayPage="displayPage" />
        <!-- <router-view/> -->
        <Footer @gotoPage="displayPage" /> 
    </div>
</template>

<script>
import Banner from './components/header/banner.vue';
import SideW from './components/aside/side.vue';
import Footer from './components/footer/footer.vue';
import MainPage from './views/MainPage.vue';

export default {
  name: "HomePage",
  data() {
    return {
      showSideBar: false,
      pageIndex: 1
    }
  },
  components: {
    Banner,
    SideW,
    Footer,
    MainPage
  },
  methods: {
    displaySideBar(val) {
      this.showSideBar = val;
    },
    displayPage(val) {       
      this.pageIndex = val;
    }
  }
}
</script>