<template>
<div>
<header class="page-header" v-bind:style="{ backgroundImage: 'url(images/contactus.jpg)' }">
  <div class="container">
    <h1>Contact Us</h1>
  </div>
  <!-- end container --> 
</header>
<!-- end page-header -->

<section class="content-section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title">                
                <h2>We’d love to hear from you</h2>
                        <p>Please send your enquiry to <u>contact@pacific-shipping.com</u>, or contact your local<br> 
                    specialists using the contact details below.</p>
                </div>
                <!-- end section-title --> 
            </div>
            <div class="col-lg-5 col-md-6">
                <div class="contact-box">
                <h5>Corporate Office (Head Office)</h5>
                <address>
                    No.36, 11th Cross, 1st Stage, Indiranagar.<br /> Bangalore - 560038, Karnataka, India<br />
                    Phone: +(91)-80-41169334 / 41678334 <br />
                    Email: <a href="#">contact@pacific-shipping.com</a>
                </address>
                <a href="#googlmap" data-fancybox="" data-width="640" data-height="360" class="custom-button">FIND US ON MAP</a>
                </div>
                <!-- end contact-box -->
            </div>
        </div>
    </div>
</section>
<section class="content-section no-bottom-spacing" id="secContact" v-bind:style="{ backgroundColor: '#f9f7ef' }">
    <ContactForm />
</section>

<!-- end content-section -->
<div class="google-maps" id="googlmap">
   <iframe src="https://maps.google.com/maps?q=No.36%2C%2011th%20Cross%2C%201st%20Stage%2C%20Indiranagar.%20Bangalore%20-%20560038%2C%20Karnataka%2C%20India&t=&z=13&ie=UTF8&iwloc=&output=embed"  frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
</div>
<br />
<br />
<Group @gotoPage="gotoPage" />
</div>
</template>

<script>
import ContactForm from '../components/pages/contactform.vue'
import Group from '../components/pages/group.vue'

export default {
    name: "Contact",
    components: {
        Group,
        ContactForm
    },
    created: function() {
        window.scrollTo(0,0);
    },
    methods: {
        gotoPage: function(val) {
            this.$emit('gotoPage', val);
            this.$emit('page', val);
        }
    }
}
</script>