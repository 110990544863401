<template>
    <aside id="sidewidget">
        <div class="inner">
            <div class="logo"> 
            <a href="index.html">
                <img src="images/logo.png" srcset="images/logo.png" alt="Image" height="150">
            </a> 
            </div>
            <!-- end logo -->
            <div class="hide-mobile">
            <p>Take the complexity out of customs Freight Solutions with customs brokerage services</p>
            <figure class="gallery"><a href="images/recent-news01.jpg" data-fancybox><img src="images/recent-news01.jpg" alt="Image"></a><a href="images/recent-news02.jpg" data-fancybox><img src="images/recent-news02.jpg" alt="Image"></a></figure>
            <h6 class="widget-title">CONTACT INFO</h6>
            <address class="address">
            <p>+(91)-80-41169334 / 41678334<br>
                <a href="#">contact@pacific-shipping.com</a></p>
            </address>
            <h6 class="widget-title">FOLLOW US</h6>
            <ul class="social-media">
                <li><a href="#"><img alt="facebook" src="images/facebook.png" height="16" /></a></li>
                <li><a href="#"><img alt="twitter" src="images/twitter.png" height="16" /> </a></li>
                <li><a href="#"><img alt="linkedin" src="images/linkedin.png" height="16" /></a></li>        
            </ul>
            </div>
            <!-- end hide-mobile -->
            <div class="show-mobile">
            <div class="site-menu">
                <ul>
                <li><a href="services.html">Services</a></li>
                <li><a href="news.html">News</a></li>
                <li><a href="about.html">About</a></li>
                <li><a href="contact.html">Contact</a></li>
                </ul>
                <br />
                <h6 class="widget-title">Follow Us</h6>
                <ul class="social-media">
                <li>
                    <a href="#"><img alt="facebook" src="images/facebook.png" height="16" /></a>
                    <a href="#"><img alt="twitter" src="images/twitter.png" height="16" /> </a>
                    <a href="#"><img alt="linkedin" src="images/linkedin.png" height="16" /></a>
                </li>       
                </ul>
            </div>
            <!-- end site-menu --> 
            </div>
            <!-- end show-mobile --> 
            <small>© 2021 Pacific Sea & Skylinks Pvt. Ltd.</small> 
        </div>
        <!-- end inner --> 
    </aside>
</template>

<script>
export default {
    name: "SideW",
    props: {
        className: String
    }
}
</script>