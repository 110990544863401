<template>
    <div>
        <header class="page-header" v-bind:style="{ backgroundImage: 'url(images/pwp_banner.jpg)' }">
            <div class="container">
                <h1>Pacific World Product</h1>
                <h4 style="color:white;">Large enough to handle, small enough to care</h4>
            </div>
            <!-- end container --> 
        </header>
        <!-- end page-header -->
        <section class="content-section">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="section-title">
                            <h2>GO GREEN GO CLEAN</h2>
                            <p>with eco friendly ARECA leaf produtcs.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="content-section" id="logistic" data-background="#f9f7ef" style="background: rgb(249, 247, 239);">
            <div class="container">    
                <div class="row align-items-center no-gutters">
                <div class="col-lg-6">
                    <div class="side-list">
                    <h2>Areca product benifits </h2>
                    <div class="list-box">
                        <ul>
                            <li><i class="lni lni-checkmark"></i> Safe to use </li>
                            <li><i class="lni lni-checkmark"></i> 100% Natural and Chemical Free</li>
                            <li><i class="lni lni-checkmark"></i> Microwavable</li>
                            <li><i class="lni lni-checkmark"></i> Different Style</li>
                            <li><i class="lni lni-checkmark"></i> Getting Rid of Plates</li>
                            <li><i class="lni lni-checkmark"></i> Easily Transportable</li>
                            <li><i class="lni lni-checkmark"></i> Better Replacement to Plastic</li>
                            <li><i class="lni lni-checkmark"></i> Biodegradable</li>   
                            <li><i class="lni lni-checkmark"></i> Areca Leaf Plates Do not Alter the Taste </li>   
                            <li><i class="lni lni-checkmark"></i> Leak-Proof, Odorless, Hygienic</li>              
                        </ul>
                    </div>
                    <!-- end list-box --> 
                    </div>
                    <!-- end side-list --> 
                </div>
                <!-- end col-6 -->
                <div class="col-lg-6">
                    <figure class="side-image full-right"> <img src="images/areca_2.jpg" alt="Image"> </figure>
                </div>
                <!-- end col-6 --> 
                </div>
                <!-- end row --> 
            </div>
            <!-- end container --> 
        </section>
        <section id="cha" data-background="#ffffff" class="content-section">
            <div class="container">
                <div class="row align-items-center no-gutters">
                    <div class="col-lg-6">
                        <figure class="side-image full-left">
                            <img src="images/areca_1.jpg" alt="Image">
                        </figure>
                    </div>
                    <div class="col-lg-6">
                        <div class="side-content right">
                            <h2>Eco Friendly Products</h2>
                            <p>The use and throw Palm Leaf Plates / Natural Leaf Plates/ Areca Leaf Plates and Cups made out of Areca Leaf are 100% biodegradable and compostable. These hygienic, non-odorous, non-toxic, light weight plates and cups made out of Areca leaf are environment friendly. An economical disposable alternative for all catering occasions. </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <br />
        <br />
        <ContactUs @gotoPage="gotoPage" />
        <Group @gotoPage="gotoPage" />
    </div>
</template>

<script>
import ContactUs from '../../components/pages/contactus.vue';
import Group from '../../components/pages/group.vue';

export default {
    name: "AirAviator",
    components: {
        ContactUs,
        Group
    },
    created: function() {
        window.scrollTo(0,0);
    },
    methods: {
        gotoPage(val) {
            window.scrollTo(0,0);
            this.$emit('page', val);
        },
    }
}
</script>